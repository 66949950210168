<template>
  <div class="">
    <loading :show="isLoading" :label="`Downloading PDF`"> </loading>
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Reconciliations</h1>
        <!-- <p class="text-xs text-gray-400">Welcome to your dashboard</p> -->
      </div>

      <div class="flex">
        <div>
          <input
            class="p-2 border w-80 rounded"
            v-model="filter"
            @keyup="search()"
            type="search"
            placeholder="Search"
          />
        </div>
        <button @click="rqFilter()" class="mx-2">
          <span>
            <i
              :class="{ 'text-blue-600': onFilter }"
              class="bx bx-filter-alt text-2xl"
            ></i>
          </span>
        </button>
      </div>

      <router-link
        to="/reconciliation/create"
        class="
          border
          px-6
          py-2
          rounded-lg
          text-sm
          flex
          justify-center
          items-center
          hover:bg-gray-100
        "
      >
        New Reconciliation
        <i class="bx bxs-plus-circle pl-1"></i>
      </router-link>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <div
              v-if="onFilter"
              class="flex grid-flow-col gap-4 bg-gray-50 p-3"
            >
              <div class="flex flex-col w-80">
                <label class="text-gray-600" for="branch"
                  >Filter by date
                </label>
                <input
                  class="p-2 border rounded text-xs"
                  type="date"
                  name="date"
                  @change="loadData(1, filter)"
                  v-model="filterDate"
                />
              </div>
            </div>
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Date
                  </th>

                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Total Reconciled
                  </th>

                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Reconcile By
                  </th>

                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Status
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only"></span>
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="reconciliations"
                class="bg-white divide-y divide-gray-200"
              >
                <tr
                  v-for="(reconciliation, i) in reconciliations.data"
                  :key="i"
                >
                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{
                            reconciliation.date | moment("dddd, MMMM Do YYYY")
                          }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    GMD
                    <b>{{
                      formatPrice(total(reconciliation.collection_reconciles))
                    }}</b>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ reconciliation.created_by }}
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <span
                      v-if="reconciliation.status"
                      class="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        uppercase
                        rounded-full
                        bg-green-100
                        text-green-800
                      "
                      >Completed</span
                    >

                    <span
                      v-if="!reconciliation.status"
                      class="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        uppercase
                        rounded-full
                        bg-red-100
                        text-red-800
                      "
                      >Pending</span
                    >
                  </td>

                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
                  >
                    <button
                      v-if="!reconciliation.status"
                      @click="
                        $router.push({
                          path: `/reconciliation/manage/${reconciliation.id}`,
                        })
                      "
                      class="
                        mx-4
                        py-2
                        px-4
                        rounded
                        border border-gray-400
                        text-gray-900
                        hover:bg-gray-50
                      "
                    >
                      Complete Reconciliation
                    </button>
                    <button
                      @click="onSelect(reconciliation)"
                      class="
                        mx-4
                        py-2
                        px-4
                        rounded
                        bg-green-400
                        text-green-900
                        hover:text-indigo-900
                      "
                    >
                      View
                    </button>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>

            <modal width="80%" height="80%" name="example">
              <!-- {{ selected }} -->
              <div>
                <div class="text-right border-b">
                  <button
                    @click="generatePdf()"
                    class="bg-red-400 text-white p-4"
                  >
                    <i class="bx bxs-file-pdf"></i> Download PDF
                  </button>
                  <button
                    @click="$modal.hide('example')"
                    class="bg-red-500 text-white p-4"
                  >
                    <i class="bx bx-x"></i>
                  </button>
                </div>
              </div>
              <div id="#table" ref="testHtml">
                <div
                  v-if="selected"
                  class="flex jestify-between text-center border-b"
                >
                  <div class="px-6 my-4 border-r">
                    <h1 class="text-sm">
                      <span class="text-gray-500">Date:</span>
                      {{ selected.date | moment("dddd, MMMM Do YYYY") }}
                    </h1>
                  </div>

                  <div class="px-6 my-4 border-r">
                    <h1 class="text-sm">
                      <span class="text-gray-500">Remarks:</span>
                      {{ selected.remarks }}
                    </h1>
                  </div>

                  <div class="px-6 my-4 border-r">
                    <h1 class="text-sm">
                      <span class="text-gray-500">Reconcile By:</span>
                      {{ selected.created_by }}
                    </h1>
                  </div>

                  <div class="px-6 my-4 border-r">
                    <h1 class="text-sm">
                      <span class="text-gray-500">Total :</span> GMD
                      <b>{{
                        formatPrice(total(selected.collection_reconciles))
                      }}</b>
                    </h1>
                  </div>
                </div>

                <div>
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                        >
                          Branch / Date
                        </th>

                        <th
                          scope="col"
                          class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                        >
                          Sales
                        </th>

                        <th
                          scope="col"
                          class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                        >
                          Deposited
                        </th>

                        <th
                          scope="col"
                          class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                        >
                          Bank
                        </th>

                        <th
                          scope="col"
                          class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                        >
                          Created By
                        </th>

                        <th
                          scope="col"
                          class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                        >
                          Slip Num - Bak Ref
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      v-if="selected"
                      class="bg-white divide-y divide-gray-200"
                    >
                      <tr
                        v-for="(
                          collection, i
                        ) in selected.collection_reconciles"
                        :key="i"
                      >
                        <td class="px-3 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div class="text-xs font-medium text-gray-900">
                                {{ collection.branch }}
                              </div>
                              <div class="text-sm text-gray-500">
                                {{ collection.date }}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td class="px-6 py-4 text-sm whitespace-nowrap">
                          GMD <b>{{ collection.total_sales }}</b>
                        </td>

                        <td class="px-6 py-4 text-sm whitespace-nowrap">
                          GMD <b>{{ collection.total_deposited }}</b>
                        </td>

                        <td class="px-6 py-4 text-sm whitespace-nowrap">
                          {{ collection.bank }}
                        </td>

                        <td class="px-6 text-sm py-4">
                          {{ collection.created_by }}
                        </td>

                        <td class="px-6 text-sm py-4">
                          {{ collection.bank_slip_number }} -
                          {{ collection.bank_reference }}
                        </td>
                      </tr>

                      <!-- More people... -->
                    </tbody>
                  </table>
                </div>
              </div>
            </modal>

            <div class="border-t" v-if="reconciliations">
              <pagination
                :data="reconciliations"
                :midSize="2"
                @clicked="loadData"
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "../../../components/Pagination";
import moment from "moment";
import { mapGetters } from "vuex";
import checkPermissionExist from "../../../mixins/checkPermissionExist";
import loading from "vue-full-loading";
// import modal from "vue-js-modal";
import "vue-js-modal/dist/styles.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import autoTable from "jspdf-autotable";
// import BranchSales from "../../components/Charts/BranchSales";

export default {
  middleware: "auth",
  components: { Pagination, loading },

  layout: "default",

  mixins: [checkPermissionExist],

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  data: () => ({
    reconciliations: null,

    filter: "",
    onFilter: false,

    // todos: [
    //   { title: "title 1", description: "description 1" },
    //   { title: "title 2", description: "description 2" },
    //   { title: "title 3", description: "description 3" },
    //   { title: "title 4", description: "description 4" },
    // ],

    selected: null,

    filterDate: "",
    isLoading: false,
  }),

  mounted() {},

  created() {
    var _this = this;
    _this.loadData(1);
    if (!this.checkPermissionExist(this.user.data, "reconciliations-read")) {
      this.$router.go(-1);
      return false;
    }
  },

  methods: {
    generatePdf() {
      // var doc = new jsPDF();

      // console.log("DOC", doc);

      var vm = this;

      var docName = `Reconciliation Report: ${moment(vm.selected.date).format(
        "dddd, MMMM Do YYYY"
      )}`;

      var fileName = `reconciliation-report-${moment(vm.selected.date).format(
        "dddd, MMMM Do YYYY"
      )}`;
      var columns = [
        { title: "Branch", dataKey: "branch" },
        { title: "Sales", dataKey: "total_sales" },
        { title: "Deposit", dataKey: "total_deposited" },
        { title: "Bank", dataKey: "bank" },
        { title: "Created By", dataKey: "created_by" },
        { title: "Bank Spip No.", dataKey: "bank_slip_number" },
        { title: "Bank Reference", dataKey: "bank_reference" },
      ];
      var doc = new jsPDF("p", "pt");
      // require("jspdf-autotable");
      doc.text(docName, 40, 20);

      doc.setLineWidth(1.0);
      doc.line(40, 30, 560, 30);

      doc.setFontSize(12);
      doc.text(
        `Reconcile By: ${
          vm.selected.created_by
        }    |   Total: GMD ${this.formatPrice(
          this.total(vm.selected.collection_reconciles)
        )}`,
        40,
        50
      );

      doc.setLineWidth(1.0);
      doc.line(40, 60, 560, 60);

      doc.setFontSize(12);
      doc.text(`Remarks: ${vm.selected.remarks}`, 40, 80);

      doc.autoTable({
        columns: columns,
        body: vm.selected.collection_reconciles,
        margin: { top: 90 },
      });
      doc.save(`${fileName}.pdf`);
    },

    onSelect(reconciliation) {
      this.selected = reconciliation;
      this.$modal.show("example");
    },

    async loadData(pageNo, filter) {
      // Submit the form
      if (filter) {
        // filter = filter;
        this.searching = true;
      } else {
        filter = "";
        this.searching = false;
      }

      try {
        const { data } = await axios.get(
          `/reconciliations?page=${pageNo}&filter=${this.filterDate}`
        );

        console.log(data);

        this.reconciliations = data;
        this.searching = false;

        // console.log("The current page is: ", this.agents.meta.current_page);

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },

    total(collections) {
      let total = [];

      Object.entries(collections).forEach(([key, val]) => {
        console.log(key);
        total.push(val.total_deposited); // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },

    search() {
      var vm = this;
      this.searching = true;
      vm.loadData(1, vm.filter);
    },

    rqFilter() {
      this.onFilter = !this.onFilter;

      if (this.onFilter) {
        this.loadBranches();
        this.loadStaffs();
      }
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
